import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import * as tableFunction from  "../../components/TableFunctions"

function clientColumnFormatter(cell, row) {
    return <div>{row.name} ({row.appName})</div>;
}

export default ({
    data,
    onClick
  }) =>
    <BootstrapTable 
        options = { {
            clearSearch: false,
            onRowClick: onClick,
            searchField: tableFunction.createCustomSearchField,
            paginationShowsTotal: tableFunction.renderShowsTotal,
            hideSizePerPage: true,
            sizePerPage: 20,
            sortName: "name",
            sortOrder: "asc"
        } }
        data={ data } 
        //data={ this.state.deleted ? this.state.clients : this.state.inactiveClients } 
        hover 
        clickable
        pagination
        search
        tableHeaderClass={"col-hidden"}>
            <TableHeaderColumn isKey dataFormat={clientColumnFormatter} dataField='name'></TableHeaderColumn>
            <TableHeaderColumn hidden={ true } dataField='appName'></TableHeaderColumn>
    </BootstrapTable>;