import { Auth } from "aws-amplify";

export function getSafe(fn, defaultVal) {
    try {
        return fn();
    } catch (e) {
        return defaultVal;
    }
}

export function convertDate(datetime) {
    let dateString = "";
    if(datetime === 0) {
        dateString = "Not Specified"
    }
    else {
        var d = new Date(parseFloat(datetime));
        dateString = d.toLocaleDateString()
    }
    return dateString;
}

export function convertDateTime(datetime) {
    let dateString = "";
    if(datetime === 0) {
        dateString = "Not Specified"
    }
    else {
        var d = new Date(parseFloat(datetime));
        dateString = d.toLocaleString()
    }
    return dateString;
}

export function convertCurrency(number) {
    let num = Number(number);
    const currencyString = "£" + Number(num.toFixed(2)).toLocaleString();
    return currencyString;
}

export function site24x7Status(number) {
    let status = "";
    switch (number) {
        case 0:
            status = "Down";
            break;
        case 1:
            status = "Up";
            break;
        case 2:
            status = "Trouble";
            break;
        case 3:
            status = "Critical";
            break;
        case 5:
            status = "Suspended";
            break;
        case 7:
            status = "Maintenance";
            break;
        case 9:
            status = "Discovery";
            break;
        case 10:
            status = "Configuration Error";
            break; 
        default:
            status = "default";
    }
    return status;
}

export async function validSession() {   
    try {     
        const resp = await Auth.currentUserInfo();   
        return Object.keys(resp).length > 0;
    } catch(e) {
        return false;
    } 
}