import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Grid, Row, Col } from "react-bootstrap";
import cronstrue from 'cronstrue';

import * as tableFunction from  "../../components/TableFunctions"

function interfaceColumnFormatter(cell, row, includeClient) {

    return  <div>
                <Grid className="interfaceRow">
                    <Row>
                        <Col sm={9}>
                            <b>
                            {includeClient && row.context + " | "}
                            {row.type}
                            {row.supplier && " | " + row.supplier} 
                            {row.name && " | " + row.name}
                            
                            </b>
                            <div className="footnote">
                                <span><i>Schedule: {row.schedule === "Not Scheduled" ? row.schedule : cronstrue.toString(row.schedule)}</i></span>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <div className="footnote right">
                                {row.package && <span><i>Package: {row.package}</i></span>}<br></br>
                                {row.application && <span><i>Application: {row.application}</i></span>}
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </div>;
}

const options = {
    clearSearch: false,
    searchField: tableFunction.createCustomSearchField,
    paginationShowsTotal: tableFunction.renderShowsTotal,
    hideSizePerPage: true,
    sizePerPage: 15,
    sortName: "context",
    sortOrder: "asc"
};

export default ({
    data,
    includeClient
  }) =>
  <BootstrapTable 
  options = { options }
  data={ data } 
  pagination
  search
  tableHeaderClass={"col-hidden"}>
    <TableHeaderColumn dataField="id" hidden={ true } isKey>id</TableHeaderColumn>
    <TableHeaderColumn dataField="context" hidden={ true }>Context</TableHeaderColumn>
    <TableHeaderColumn dataField="supplier" hidden={ true }>Supplier</TableHeaderColumn>
    <TableHeaderColumn dataField="application" hidden={ true }>Application</TableHeaderColumn>
    <TableHeaderColumn dataField="type" hidden={ true }>Type</TableHeaderColumn>
    <TableHeaderColumn dataField="package" hidden={ true }>Package</TableHeaderColumn>
    <TableHeaderColumn dataField="name" formatExtraData={ includeClient } dataFormat={interfaceColumnFormatter}></TableHeaderColumn>
</BootstrapTable>;