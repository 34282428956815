import React, { Component } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { Image, Grid, Row, Col } from "react-bootstrap";
import { API } from "aws-amplify";

import config from "../../config/chartConfig"
import "./Client.css";

async function renderChart(token, chartObj, context) {
    const sdk = new ChartsEmbedSDK({
      baseUrl: chartObj.BASE_URL, 
      chartId: chartObj.ID,
      height: chartObj.HEIGHT,
      width: chartObj.WIDTH,
      showAttribution: false,
      filter: {"context": context},
      getUserToken: function () {
        return token;
    }});
    const chart = sdk.createChart({ id: chartObj.ID });
    await chart.render(document.getElementById(chartObj.NAME));
}

export default class ClientStats extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            token: "",
            errorRaised: false,
            error: ""
        }; 
    }

    async componentDidMount() {

        try {

            const token = await this.token();
            this.setState({ token: token });

            this.setState({ isLoading: false });

            const context = this.props.location.state.detail.appName;
            const charts = config.charts.CLIENT;

            for (let i = 0; i < charts.length; i++ ) {
                renderChart(this.state.token, charts[i], context);
            }

        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
            alert(e);
        }
      
    }


    token() {
        return API.get("external", "/mongoChartToken");
    }
      
    render() {

        return (
            <div className="Client">
                {!this.state.isLoading
                    ?   <div className="Stats">
                            <Grid>
                                <Row>
                                    <Col sm={7}>
                                        <div id="JOBS_CREATED_BY_WEEK"></div>
                                    </Col>
                                    <Col sm={3}>
                                        <div id="JOBS_CREATED_BY_WORLOGTYPE"></div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={7}>
                                        <div id="APPTS_CREATED_BY_WEEK"></div>
                                    </Col>
                                    <Col sm={3}>
                                        <div id="APPTS_CREATED_BY_TYPE"></div>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    :   <div className="loadingContainer">
                            <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                        </div>
                }
            </div>
        );
    }

}

