import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, NavItem, Image } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import "./App.css";

import Routes from "./Routes";

class App extends Component {
  _isMounted = false;
  constructor(props) {
      super(props);
  
      this.state = {
        isAuthenticated: false,
        isAuthenticating: true,
        isAdmin: false,
        isSuperAdmin: false
      };

  }

  async componentDidMount() {

    this._isMounted = true;

      try {

          await Auth.currentSession();

          const { attributes = {} } = await Auth.currentUserInfo();
          const adminAttribute = attributes['custom:admin'];
          const userAdminAttribute = attributes['custom:user_admin'];

          if (adminAttribute === "1") {
              this.userIsAdmin(true);
          }

          if (userAdminAttribute === "1") {
              this.userIsSuperAdmin(true);
          }

          this.userHasAuthenticated(true);

      }
      catch(e) {
        this.userHasAuthenticated(false);
        this.props.history.push("/login");
      }
    
      this.setState({ isAuthenticating: false });  
  }
  
  userHasAuthenticated = authenticated => {
      if (this._isMounted) {
        this.setState({
          isAuthenticated: authenticated,
        });
      }
  }

  userIsAdmin = admin => {
    if (this._isMounted) {
      this.setState({ isAdmin: admin });
    }
  }

  userIsSuperAdmin = admin => {
    if (this._isMounted) {
      this.setState({ isSuperAdmin: admin });
    }
  }

  handleLogout = async event => {
      await Auth.signOut();
      this.userHasAuthenticated(false);
      this.props.history.push("/login");
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
   
  
  render() {
    
      const childProps = {
          isAuthenticated: this.state.isAuthenticated,
          isAdmin: this.state.isAdmin,
          isSuperAdmin: this.state.isSuperAdmin,
          userHasAuthenticated: this.userHasAuthenticated,
          userIsAdmin: this.userIsAdmin,
          userIsSuperAdmin: this.userIsSuperAdmin
      };
  
      return (
        !this.state.isAuthenticating &&
        <div className="App container">
          {this.state.isAuthenticated &&
            <div className="Header clearfix">
              <Link to="/">
                <Image src="/onesource-logo.png"></Image>
              </Link>
              <Nav bsStyle="pills" pullRight>
                <Fragment>
                  <LinkContainer to="/client">
                    <NavItem>Clients</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/feature">
                    <NavItem>Features</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/instance">
                    <NavItem>Instances</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/interface">
                    <NavItem>Interfaces</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/reports">
                    <NavItem>Reports</NavItem>
                  </LinkContainer>
                  <LinkContainer to="/profile">
                    <NavItem>Profile</NavItem>
                  </LinkContainer>
                  {this.state.isSuperAdmin &&
                    <LinkContainer to="/users">
                      <NavItem>Users</NavItem>
                    </LinkContainer>
                  }
                  <NavItem onClick={this.handleLogout}>Logout</NavItem>
                </Fragment>
              </Nav>
            </div>
          }
          <Routes childProps={childProps} />
        </div>
      );
    }  
}

export default withRouter(App);
