import React, { Component } from "react";
import { HelpBlock, FormGroup, FormControl, ControlLabel, Image, Alert, Button } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import { Auth } from "aws-amplify";

import "./Signup.css";

export default class Signup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      name: "",
      surname: "",
      confirmPassword: "",
      confirmationCode: "",
      newUser: null,
      requireAuthorisation: false,
      errorRaised: false,
      error: ""
    };
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }


  handleLoginClick() {
    this.props.history.push("/login");
  }

  validateForm() {

    let oneserveEmail = false;
    if(this.state.email.indexOf("@oneserve.co.uk", this.state.email.length - "@oneserve.co.uk".length) !== -1)
    {
      oneserveEmail = true;
    }

    return (
      this.state.email.length > 0 &&
      this.state.password.length > 0 &&
      this.state.name.length > 0 &&
      this.state.surname.length > 0 &&
      this.state.password === this.state.confirmPassword &&
      oneserveEmail
    );
  }

  validateConfirmationForm() {
    return this.state.confirmationCode.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ errorRaised: false });
    this.setState({ isLoading: true });
  
    try {
      const newUser = await Auth.signUp({
        username: this.state.email.toLowerCase(),
        password: this.state.password,
        attributes: {
          'name': this.state.name,
          'family_name': this.state.surname,
          'custom:admin': '0',
          'custom:user_admin': '0',
          'custom:signup_complete': '0',
        }
      });

      this.setState({
        newUser
      });
    } catch (e) {
      this.setState({ isLoading: false });
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });
    }
  
    this.setState({ isLoading: false });
  }
  
  handleConfirmationSubmit = async event => {
    event.preventDefault();
    this.setState({ errorRaised: false });
    this.setState({ isLoading: true });
  
    try {
      await Auth.confirmSignUp(this.state.email.toLowerCase(), this.state.confirmationCode);
      this.setState({ requireAuthorisation: true });
    } catch (e) {
      this.setState({ isLoading: false });
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });
    }
    this.setState({ isLoading: false });
  }
  
  renderConfirmationForm() {
    return (
      <div>
      {this.state.requireAuthorisation
        ? <div className="confirmStep">
            <Alert className="contactAdminAlert">Please contact your administrator to activate your account which will allow you to log in</Alert>
            <Button onClick={this.handleLoginClick} block>Back to Login Screen</Button>
          </div>
        : <form onSubmit={this.handleConfirmationSubmit}>
            <FormGroup controlId="confirmationCode" bsSize="large">
              <ControlLabel>Confirmation Code</ControlLabel>
              <FormControl
                autoFocus
                type="tel"
                value={this.state.confirmationCode}
                onChange={this.handleChange}
              />
              <HelpBlock>Please check your email for the code.</HelpBlock>
            </FormGroup>
            <LoaderButton
              block
              bsSize="large"
              disabled={!this.validateConfirmationForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Verify"
              loadingText="Verifying…"
            />
          </form>
        }
      </div>
    );
  }

  renderForm() {
    return (
      <form onSubmit={this.handleSubmit}>
        <FormGroup controlId="email" bsSize="large">
          <ControlLabel>Email</ControlLabel>
          <FormControl
            autoFocus
            type="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <HelpBlock>Must be a oneserve.co.uk email address</HelpBlock>
        </FormGroup>
        <FormGroup controlId="name" bsSize="large">
          <ControlLabel>First Name</ControlLabel>
          <FormControl
            type="text"
            value={this.state.name}
            onChange={this.handleChange}
          />
        </FormGroup>
        <FormGroup controlId="surname" bsSize="large">
        <ControlLabel>Surname</ControlLabel>
        <FormControl
          type="text"
          value={this.state.surname}
          onChange={this.handleChange}
        />
      </FormGroup>
      <hr></hr>
        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>Password</ControlLabel>
          <FormControl
            value={this.state.password}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <HelpBlock>Must be a minimum of 8 characters and a mix of lowercase, upercase, numbers and special characters</HelpBlock>
        <FormGroup controlId="confirmPassword" bsSize="large">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl
            value={this.state.confirmPassword}
            onChange={this.handleChange}
            type="password"
          />
        </FormGroup>
        <LoaderButton
          block
          bsSize="large"
          bsStyle="success"
          disabled={!this.validateForm()}
          type="submit"
          isLoading={this.state.isLoading}
          text="Signup"
          loadingText="Signing up…"
        />
      </form>
    );
  }

  render() {
    return (
      <div className="Signup">
        
        <div className="logo">
          <Image src="./onesource-logo.png"></Image>
        </div>
        {this.state.errorRaised &&
          <div className="signupError">
            <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                <h5><b>Oh No! There was a problem...</b></h5>
                <p>{this.state.error}</p>
            </Alert>
          </div>
        }
        {this.state.newUser === null
          ? this.renderForm()
          : this.renderConfirmationForm()}
      </div>
    );
  }
}
