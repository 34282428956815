import React, { Component } from "react";
import { Image, Button, ButtonToolbar, Modal, FormGroup, FormControl } from "react-bootstrap";
import FeatureTable from "./FeatureTable"
import { Auth, API } from "aws-amplify";

import "./Client.css";

export default class ClientFeatures extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            deleted: false,
            feature: "",
            value: "",
            note: "",
            features: [],
            modalFeatureShow: false,
            user: "",
            errorRaised: false,
            error: ""
        }; 

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleFeatureShow = this.handleFeatureShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.addFeature = this.addFeature.bind(this);
    }

    validateForm() {
        return this.state.feature.length > 0
        && this.state.value.length > 0;
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
        this.setState({ modalFeatureShow: false });
    }

    handleFeatureShow() {
        this.setState({ modalFeatureShow: true });
    }

    handleClose() {
        this.setState({ feature: "" });
        this.setState({ value: "" });
        this.setState({ note: "" });
        this.setState({ modalFeatureShow: false });
    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    async componentDidMount() {

        try {
            this.setState({ isLoading: true });
            const features = await this.features();
            this.setState({ features });
            const { attributes = {} } = await Auth.currentUserInfo();
            const user = attributes['name'] + " " + attributes['family_name'];
            this.setState({ user: user });

        } catch (e) {
            alert(e);
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }
      
        this.setState({ isLoading: false });

    }

    features() {
        return API.get("internal", `/features/${this.props.match.params.clientId}`);
    }

    addFeature() {

        try {

            const feature = {
                "hubspotId": this.props.match.params.clientId,
                "context": this.props.location.state.detail.appName,
                "name": this.state.feature,
                "value": this.state.value,
                "note": this.state.note,
                "deleted": false,
                "createdOn": Date.now(),
                "modifiedOn": Date.now(),
                "createdBy": this.state.user,
                "modifiedBy": this.state.user
            };

            API.post("internal", "/feature", {
                    body: feature
                })
                .then(result => this.refreshList())
                .catch(err => alert(err));     

        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }

    }

    async refreshList() {
        this.handleClose();
        const features = await this.features();
        this.setState({ features });
    }

    renderFeaturesList() {
        return ( <FeatureTable data={this.state.features} includeClient={ false } /> );
    }
      
    render() {

        return (
            <div className="Client">

                {!this.state.isLoading
                    ? 
                    <div>
                        <ButtonToolbar className="addButton">
                        {this.props.isAdmin &&
                            <Button onClick={this.handleFeatureShow} className="adminButton" bsStyle="primary">Add Feature</Button>
                        }
                        </ButtonToolbar>
                        {this.renderFeaturesList()}
                    </div>
                :   <div className="loadingContainer">
                        <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                    </div>
                }
                <Modal backdrop="static" keyboard={false} bsSize="lg" className="noteModal" show={this.state.modalFeatureShow} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            New Feature
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="feature" bsSize="large">
                            <FormControl
                                autoFocus
                                type="text"
                                value={this.state.feature}
                                onChange={this.handleChange}
                                placeholder="Feature"
                            />
                        </FormGroup>
                        <FormGroup controlId="value" bsSize="large">
                            <FormControl
                                type="text"
                                value={this.state.value}
                                onChange={this.handleChange}
                                placeholder="Value"
                            />
                        </FormGroup>
                        <FormGroup controlId="note" bsSize="large" className="notes">
                            <FormControl
                                type="text"
                                componentClass="textarea"
                                value={this.state.note}
                                onChange={this.handleChange}
                                placeholder="Notes"
                            />
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={!this.validateForm()} bsStyle="success" onClick={this.addFeature}>Add Feature</Button>
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }

}

