import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as utility from "../../components/UtilityFunctions.js";
import * as tableFunction from  "../../components/TableFunctions"

export default ({
    data,
    onClick
  }) =>
    <BootstrapTable 
        options = { {
            clearSearch: false,
            onRowClick: onClick,
            searchField: tableFunction.createCustomSearchField,
            sortName: "name",
            sortOrder: "asc"
        } }
        data={ data } 
        clickable
        hover
        search>
         <TableHeaderColumn width='40%' dataField="name" isKey>Monitor Name</TableHeaderColumn>
         <TableHeaderColumn dataField="status" dataFormat={ utility.site24x7Status }>Status</TableHeaderColumn>
         <TableHeaderColumn dataField="last_polled_time">Last Polled Time</TableHeaderColumn>
         <TableHeaderColumn dataField="attribute_value">Response (ms)</TableHeaderColumn>
   </BootstrapTable>;