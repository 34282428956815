import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as tableFunction from  "../../components/TableFunctions"
import * as utility from "../../components/UtilityFunctions.js";


function textFormat(cell, row) {
    let text = utility.getSafe(() => row.subdomain[0].toUpperCase() + row.subdomain.slice(1), 'Unknown');
    return text;
}

function dateFormat(cell, row) {
    let date = utility.convertDateTime(utility.getSafe(() => row.modifiedOn, 0))
    return date;
}

function linkFormat(cell, row) {
    let link = "https://" + row.subdomain + ".oneserve.co.uk/" + row.name;
    return 'name' in row ? <a rel="noopener noreferrer" href={link} target="_blank">[Link]</a> : '';
}

function rowClassNameFormat(row, rowIdx) {
    return 'table-' + row.subdomain;
}

const options = {
    clearSearch: false,
    searchField: tableFunction.createCustomSearchField,
    paginationShowsTotal: tableFunction.renderShowsTotal,
    hideSizePerPage: true,
    sizePerPage: 20,
    sortName: "name",
    sortOrder: "asc"
};

export default ({
    data,
    deleteButton,
    includeClient
  }) =>
  <BootstrapTable 
    options = { options }
    data={ data } 
    pagination={ includeClient }
    trClassName={ !includeClient && rowClassNameFormat }
    search={ includeClient }>
        <TableHeaderColumn isKey hidden={ true } dataField="_id"></TableHeaderColumn>
        <TableHeaderColumn hidden={ !includeClient } dataSort={ true } dataField="appName">Client</TableHeaderColumn>
        <TableHeaderColumn dataSort={ true } dataField="name">Instance</TableHeaderColumn>
        <TableHeaderColumn width='140' dataSort={ true } dataField="subdomain" dataFormat={ textFormat }>Domain</TableHeaderColumn>
        { !deleteButton && 
            <TableHeaderColumn width='140' dataSort={ true } dataField="version">Version</TableHeaderColumn>
        }
        <TableHeaderColumn dataSort={ true } dataField="modifiedOn" dataFormat={ dateFormat }>Modified</TableHeaderColumn>
        { !deleteButton && 
            <TableHeaderColumn width='90' dataField="context" dataAlign="center" dataFormat={ linkFormat }>#</TableHeaderColumn>
        }
        { deleteButton && 
            <TableHeaderColumn dataAlign="center" dataField="button" dataFormat={ deleteButton } >Edit</TableHeaderColumn>
        }
  </BootstrapTable>;