import React from "react";
import { Label, Glyphicon, Tooltip, OverlayTrigger } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import * as tableFunction from  "../../components/TableFunctions"
import * as utility from "../../components/UtilityFunctions.js";


function statusFormatter(cell, row) {
    
    let statusClass = "";

    switch (row.status) {
        case "open":
            statusClass = "danger";
            break;
        case "pending":
            statusClass = "primary";
            break;
        case "new":
            statusClass = "warning";
            break;
        default:
            statusClass = "default";
    }

    return <Label bsStyle={statusClass}>{row.status.charAt(0).toUpperCase()}</Label>;
}

function typeFormatter(cell, row) {

    let type = utility.getSafe(() => row.type, "");

    if (type !== "" && type !== null)  
        type = row.type[0].toUpperCase() + row.type.slice(1);

    return type;
}

function priorityFormatter(cell, row) {
    
    let priorityGlyph = "";

    switch (row.priority) {
        case "low":
            priorityGlyph = "arrow-down text-success";
            break;
        case "normal":
            priorityGlyph = "arrow-right text-primary";
            break;
        case "high":
            priorityGlyph = "arrow-up text-danger";
            break;
        case "urgent":
            priorityGlyph = "warning-sign text-danger";
            break;
        default:
            priorityGlyph = "question-sign";
    }

    return <Glyphicon glyph={priorityGlyph}/>;
}

function subjectFormatter(cell, row) {
    
    const tooltip = (
        <Tooltip id={row.id}>
          {row.description}
        </Tooltip>
    );

    return  <OverlayTrigger placement="right" overlay={tooltip}>
                <span className="zendeskOverlay">{row.subject}</span>
            </OverlayTrigger>;
}


export default ({
    data,
    onClick,
    includeClient
  }) =>
   
  <BootstrapTable 
        options = { {
            onRowClick: onClick,
            searchField: tableFunction.createCustomSearchField,
            sortName: "id",
            sortOrder: "desc"
        } }
        data={ data } 
        hover
        search
        clickable
        condensed>
        <TableHeaderColumn isKey name='id' dataAlign='center' dataField='id' width='85'>#</TableHeaderColumn>
        <TableHeaderColumn name='status'  dataAlign='center' dataField='status' width='65' dataFormat={ statusFormatter }>Status</TableHeaderColumn>
        <TableHeaderColumn name='type' dataAlign='center' dataField='type' width='90' dataFormat={ typeFormatter }>Type</TableHeaderColumn>
        <TableHeaderColumn name='subject' dataField='subject' dataFormat={ subjectFormatter } Style="word-wrap: break-word;">Subject</TableHeaderColumn>
        <TableHeaderColumn name='priority'  dataAlign='center' dataField='priority' width='70' dataFormat={ priorityFormatter }>Priority</TableHeaderColumn>
    </BootstrapTable>;