import React from "react";
import { Button, Modal } from "react-bootstrap";

export default ({
    modalShow,
    user,
    onClose,
    enableUser,
    disableUser,
    adminUser,
    ...props
  }) =>
    <Modal className="userModal" show={modalShow} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">
                {user &&
                    user.name + " " + user.family_name
                }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {user &&
            <div>
                <h6><strong>Email</strong></h6>
                <span>{user.email}</span>
                <h6><strong>Created</strong></h6>
                <span>{user.createdDate}</span>
                <h6><strong>Modified</strong></h6>
                <span>{user.modifiedDate}</span>
            </div>  
        }
        </Modal.Body>
        <Modal.Footer>
            {user["custom:admin"] === "0"
                ? <Button bsStyle="success" onClick={adminUser}>Make Admin</Button>
                : <Button bsStyle="danger" onClick={adminUser}>Remove Admin Permissons</Button>
            }
            {user.enabled
                ? <Button bsStyle="danger" onClick={disableUser}>Disable User</Button>
                : <Button bsStyle="success" onClick={enableUser}>Enable User</Button>
            }
        </Modal.Footer>
    </Modal>;