import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Glyphicon } from "react-bootstrap";

import * as utility from "../../components/UtilityFunctions.js";

function contactColumnFormatter(cell, row) {
    return <div className="contacts"> 
                <b><h5>{row.properties.firstname.value} {row.properties.lastname.value}</h5></b>
                <i><h5>{utility.getSafe(() => row.properties.jobtitle.value, 'Not Specified')}</h5></i>
                <Glyphicon glyph="phone-alt"/><span>:</span> {utility.getSafe(() => row.properties.phone.value, 'Not Specified')} <br></br>
                <Glyphicon glyph="envelope"/><span>:</span> {utility.getSafe(() => row.properties.email.value, 'Not Specified')}
            </div>;
}

export default ({
    data,
    includeClient
  }) =>
    <BootstrapTable 
        bordered={ false }
        data={ data } 
        trClassName={"noTopRowBorder"}
        tableHeaderClass={"col-hidden"}>
            <TableHeaderColumn isKey name='vid' dataFormat={contactColumnFormatter} dataField='vid'></TableHeaderColumn>
    </BootstrapTable>;