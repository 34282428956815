import React, { Component } from 'react';
import {
  Grid,
  Row,
  Col,
  Panel,
  Image,
  Glyphicon,
  Button,
  Alert,
} from 'react-bootstrap';
import { API } from 'aws-amplify';
import InstanceTable from './InstanceTable';
import ZendeskTable from './ZendeskTable';
import ContactTable from './ContactTable';

import * as utility from '../../components/UtilityFunctions.js';

import './Client.css';

function ragStatus(status, description) {
  let ragStatusClass = '';

  switch (status) {
    case 'Leaving':
      ragStatusClass = 'danger';
      break;
    case 'Red':
      ragStatusClass = 'danger';
      break;
    case 'Amber':
      ragStatusClass = 'warning';
      break;
    case 'Green':
      ragStatusClass = 'success';
      break;
    default:
      ragStatusClass = 'success';
  }

  return (
    <Alert className="rag" bsStyle={ragStatusClass}>
      <strong>RAG - {status}</strong>
      <p className="address">{description}</p>
    </Alert>
  );
}

export default class ClientGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coreIsLoading: true,
      contactsIsLoading: true,
      zendeskIsLoading: true,
      insatanceIsLoading: true,
      hubspotData: '',
      hubspotContacts: '',
      zendeskData: [],
      instances: '',
      errorRaised: false,
      error: '',
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }

  async componentDidMount() {
    try {
      if (this.props.location.state === undefined) {
        this.props.history.push('/client');
      }

      /* CORE DATA SECTION */

      const hubspotData = await this.getHubspotCompany(
        this.props.location.state.detail.hubspotId
      );

      this.setState({ hubspotData: hubspotData });
      this.setState({ coreIsLoading: false });

      /* INSTANCE SECTION */

      const instances = await this.getInstances(
        this.props.location.state.detail.hubspotId
      );

      for (let i = 0; i < instances.length; i++) {
        let context = await this.getAppContext(instances[i].context);

        if (context.length > 0) {
          instances[i].name = context[0].name;
        }
      }

      this.setState({ instances: instances });
      this.setState({ insatanceIsLoading: false });

      /* CONTACT DATA SECTION */
      const hubspotContactList = await this.getHubspotContactList(
        this.props.location.state.detail.hubspotId
      );

      /* Filter and recreate Hubspot Contact List data for table consumption (as the API returns all archived contacts) */
      let contactjson = JSON.parse(JSON.stringify(hubspotContactList));
      let keys = Object.keys(contactjson);

      const filteredList = [];

      for (var i = 0; i < keys.length; i++) {
        if (
          typeof contactjson[keys[i]].properties.role_type !== 'undefined' &&
          contactjson[keys[i]].properties.role_type.value.indexOf(
            'Key Contact (Onesource)'
          ) > 0
        )
          filteredList.push(contactjson[keys[i]]);
      }

      this.setState({ hubspotContacts: filteredList });
      this.setState({ contactsIsLoading: false });

      /* ZENDESK DATA SECTION */

      let orgId = parseInt(
        this.props.location.state.detail.zendeskOrganisationId
      );

      if (!isNaN(orgId)) {
        const zendeskData = await this.getZendeskData(
          this.props.location.state.detail.zendeskOrganisationId
        );
        this.setState({ zendeskData: zendeskData });
      } else {
        this.setState({
          error:
            'Zendesk organisation ID not set - please set this to view zendesk tickets',
        });
        this.setState({ errorRaised: true });
      }

      this.setState({ zendeskIsLoading: false });
    } catch (e) {
      this.setState({ coreIsLoading: false });
      this.setState({ insatanceIsLoading: false });
      this.setState({ contactsIsLoading: false });
      this.setState({ zendeskIsLoading: false });
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });
    }
  }

  getHubspotCompany(companyId) {
    return API.get('external', '/company/' + companyId);
  }

  getHubspotContactList(companyId) {
    return API.get('external', '/contacts/' + companyId);
  }

  getInstances(companyId) {
    return API.get('internal', '/instances/' + companyId);
  }

  getZendeskData(organisationId) {
    return API.get('external', '/tickets/' + organisationId);
  }

  getAppContext(context) {
    return API.get('internal', '/databases/' + context);
  }

  edit = (event) => {
    this.props.history.push({
      pathname: '/adminClient/' + this.props.location.state.detail.hubspotId,
      state: this.props.location.state,
      instances: this.state.instances,
    });
  };

  handleZendeskClick = (event) => {
    window.open(
      'https://oneserve.zendesk.com/agent/tickets/' + event.id,
      '_blank'
    );
  };

  renderContactsList() {
    return (
      <ContactTable data={this.state.hubspotContacts} includeClient={false} />
    );
  }

  renderInstanceList() {
    return <InstanceTable data={this.state.instances} includeClient={false} />;
  }

  renderZendeskList() {
    return (
      <ZendeskTable
        data={this.state.zendeskData.results}
        includeClient={false}
        onClick={this.handleZendeskClick}
      />
    );
  }

  render() {
    return (
      <div>
        {this.state.errorRaised && (
          <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
            <h5>
              <b>Oh No! There was a problem...</b>
            </h5>
            <p>{this.state.error}</p>
          </Alert>
        )}
        {this.props.isAdmin && (
          <Button onClick={this.edit} className="adminButton" bsStyle="primary">
            Edit
          </Button>
        )}
        <Grid>
          {!this.state.coreIsLoading ? (
            <Row className="show-grid">
              <Col sm={12}>
                <Panel id="client-panel" defaultExpanded>
                  <Panel.Heading>
                    <Panel.Title toggle>About</Panel.Title>
                  </Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>
                      <Grid>
                        <Row>
                          <Col sm={9}>
                            <Grid>
                              <Row>
                                <Col sm={3}>
                                  <div className="address">
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties
                                          .address.value
                                    )}
                                    <br></br>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties
                                          .address2.value
                                    )}
                                    <br></br>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties.city
                                          .value
                                    )}
                                    <br></br>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties.zip
                                          .value
                                    )}
                                    <br></br>
                                    <Glyphicon glyph="phone-alt" />
                                    <span>:</span>{' '}
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties.phone
                                          .value,
                                      'Not Specified'
                                    )}
                                  </div>
                                </Col>
                                <Col sm={5}>
                                  <div className="address">
                                    <span>Product: </span>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties
                                          .product.value,
                                      'Not Specified'
                                    )}
                                    <br></br>
                                    <span>Customer Since: </span>
                                    {utility.convertDate(
                                      utility.getSafe(
                                        () =>
                                          this.state.hubspotData.properties
                                            .customer_since.value,
                                        0
                                      )
                                    )}
                                    <br></br>
                                    <span>Owner: </span>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties.owner,
                                      'Not Specified'
                                    )}
                                    <br></br>
                                    <span>Reference Site: </span>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties
                                          .reference_site.value,
                                      'Not Specified'
                                    )}
                                    <br></br>
                                    {/*
                                                                        <span>Total Invoice Value: </span>{utility.convertCurrency(utility.getSafe(() => this.state.hubspotData.properties.total_revenue.value, 0))}
                                                                        */}
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={9}>
                                  <div className="about">
                                    <b>
                                      {utility.getSafe(
                                        () =>
                                          this.state.hubspotData.properties
                                            .industry.value,
                                        'Not Specified'
                                      )}
                                    </b>
                                    <br></br>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties
                                          .description.value,
                                      'Not Specified'
                                    )}
                                    <br></br>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm={9}>
                                  <hr />
                                  <div>
                                    <b>Current Status</b>
                                    <br></br>
                                    {utility.getSafe(
                                      () =>
                                        this.state.hubspotData.properties
                                          .current_status.value,
                                      'Not Specified'
                                    )}
                                    <br></br>
                                  </div>
                                </Col>
                              </Row>
                            </Grid>
                          </Col>
                          <Col sm={3}>
                            {ragStatus(
                              utility.getSafe(
                                () =>
                                  this.state.hubspotData.properties.rag_status
                                    .value,
                                'Green'
                              ),
                              utility.getSafe(
                                () =>
                                  this.state.hubspotData.properties.rag_reason
                                    .value,
                                ''
                              )
                            )}
                          </Col>
                        </Row>
                      </Grid>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </Col>
            </Row>
          ) : (
            <div className="loadingContainer">
              <Image
                alt="loading"
                src={process.env.PUBLIC_URL + '/loading.gif'}
                responsive
              />
            </div>
          )}
          <Row className="show-grid">
            <Col sm={8}>
              {!this.state.insatanceIsLoading ? (
                <Panel id="instance-panel" defaultExpanded>
                  <Panel.Heading>
                    <Panel.Title toggle>Instances</Panel.Title>
                  </Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>{this.renderInstanceList()}</Panel.Body>
                  </Panel.Collapse>
                </Panel>
              ) : (
                <div className="loadingContainer">
                  <Image
                    alt="loading"
                    src={process.env.PUBLIC_URL + '/loading.gif'}
                    responsive
                  />
                </div>
              )}
              {!this.state.zendeskIsLoading ? (
                <Panel id="zendesk-panel" defaultExpanded>
                  <Panel.Heading>
                    <Panel.Title toggle>Zendesk Tickets</Panel.Title>
                  </Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>
                      <div className="zendesk">{this.renderZendeskList()}</div>
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              ) : (
                <div className="loadingContainer">
                  <Image
                    alt="loading"
                    src={process.env.PUBLIC_URL + '/loading.gif'}
                    responsive
                  />
                </div>
              )}
            </Col>
            {!this.state.contactsIsLoading ? (
              <Col sm={4}>
                <Panel id="contacts-panel" defaultExpanded>
                  <Panel.Heading>
                    <Panel.Title toggle>Key Contacts</Panel.Title>
                  </Panel.Heading>
                  <Panel.Collapse>
                    <Panel.Body>{this.renderContactsList()}</Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </Col>
            ) : (
              <div className="loadingContainer">
                <Image
                  alt="loading"
                  src={process.env.PUBLIC_URL + '/loading.gif'}
                  responsive
                />
              </div>
            )}
          </Row>
        </Grid>
      </div>
    );
  }
}
