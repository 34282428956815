import React, { Component } from "react";
import { Image, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import InterfaceTable from "./InterfaceTable"
import { API } from "aws-amplify";

import "./Client.css";


export default class ClientInterfaces extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: false,
            interface: "",
            interfaces: [],
            errorRaised: false,
            error: ""
        }; 
        this.handleDismiss = this.handleDismiss.bind(this);
        this.filterList = this.filterList.bind(this);
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
    }

    async componentDidMount() {

        try {
            this.setState({ isLoading: true });
            const interfaces = await this.interfaces();
            this.setState({ allInterfaces: interfaces });
            this.setState({ interfaces: interfaces });
        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }
      
        this.setState({ isLoading: false });

    }

    filterList(filter) {
        let interfaces = this.state.allInterfaces;
        if (filter !== "all") {
            interfaces = interfaces.filter((inter) => inter.type === filter)
        }
        this.setState({ interfaces : interfaces });
    }

    interfaces() {
        return API.get("internal", `/interfaces/${this.props.match.params.clientId}`);
    }

    renderInterfaceList() {
        return ( <InterfaceTable data={this.state.interfaces} includeClient={ false } /> );
    }

    render() {

        return (
            <div className="Client">
                {!this.state.isLoading
                    ? 
                    <div>
                        <ButtonToolbar className="addButton pullRight">
                            <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                                <ToggleButton bsStyle="primary" onClick={() => this.filterList("all")} value={1}>All</ToggleButton>
                                <ToggleButton bsStyle="primary" onClick={() => this.filterList("Core")} value={2}>Core</ToggleButton>
                                <ToggleButton bsStyle="primary" onClick={() => this.filterList("Email")} value={3}>Email</ToggleButton>
                                <ToggleButton bsStyle="primary" onChange={() => this.filterList("Baris")} value={4}>Baris</ToggleButton>
                                <ToggleButton bsStyle="primary" onChange={() => this.filterList("Datacut")} value={5}>Datacut</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                        {this.renderInterfaceList()}
                    </div>
                :   <div className="loadingContainer">
                        <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                    </div>
                }
            </div>
        );
    }

}

