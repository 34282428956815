export default {
    charts:{
      CLIENT:[
        {
          NAME: "JOBS_CREATED_BY_WEEK",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "0bf93c06-0799-4dcd-b1fb-d7e6ab5a9453",
          WIDTH: 700,
          HEIGHT: 400
        },
        {
          NAME: "JOBS_CREATED_BY_WORLOGTYPE",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "fb028580-dc8a-4b79-920a-951a2718f7f1",
          WIDTH: 500,
          HEIGHT: 400
        },
        {
          NAME: "APPTS_CREATED_BY_WEEK",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "9502bd49-95af-4010-870a-8bccd80edcd6",
          WIDTH: 700,
          HEIGHT: 400
        },
        {
          NAME: "APPTS_CREATED_BY_TYPE",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "d761b85d-b2c9-401a-92fd-16547f076780",
          WIDTH: 500,
          HEIGHT: 400
        }
      ],
      GENERAL:[
        {
          NAME: "S3 Usage Report by Client",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "c90bbf6b-e847-4dc1-843d-1dec4f4150b8",
          WIDTH: 900,
          HEIGHT: 600
        },
        {
          NAME: "Weekly Job Count by Client",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "bb1e9066-23c1-48d8-b4b0-45b6261c0c55",
          WIDTH: 900,
          HEIGHT: 600
        },
        {
          NAME: "Monthly Job/Appointment Count",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "fc66eb3b-1516-472c-8c95-7990bce5b355",
          WIDTH: 900,
          HEIGHT: 600
        },
        {
          NAME: "Total Appointment Count by Type",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "d761b85d-b2c9-401a-92fd-16547f076780",
          WIDTH: 900,
          HEIGHT: 600
        },
        {
          NAME: "Total Database Disk Usage",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "bc1d71a7-9ddd-4f2b-99ea-5f8e2ec749e6",
          WIDTH: 900,
          HEIGHT: 600
        },
        {
          NAME: "Total Login Count by Month / Year",
          BASE_URL: "https://charts.mongodb.com/charts-onesource-acckl",
          ID: "0e66af52-fdb8-40ab-a341-4f77a9078764",
          WIDTH: 900,
          HEIGHT: 600
        }

      ]
    }
};


  