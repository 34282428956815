import React from "react";
import { SearchField } from 'react-bootstrap-table';

export function createCustomSearchField(props) {
    return (
        <SearchField
            className='searchField'
            defaultValue={ props.defaultSearch }
            placeholder={ props.searchPlaceholder }/>
    );
}

export function renderShowsTotal(start, to, total) {
    return (
        <p className='totals'>
            { start } - { to } of { total }
        </p>
    );
}