import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel, Image, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import LoaderButton from "../../components/LoaderButton";

import { Auth } from "aws-amplify";

import "./Login.css";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: "",
      errorRaised: false,
      error: ""
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async event => {

    event.preventDefault();
    this.setState({ isLoading: true });
  
    try {

      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      
      const { attributes = {} } = await Auth.currentUserInfo();
      const adminAttribute = attributes['custom:admin'];
      const userAdminAttribute = attributes['custom:user_admin'];

      if (adminAttribute === "1") {
          this.props.userIsAdmin(true);
      }

      if (userAdminAttribute === "1") {
          this.props.userIsSuperAdmin(true);
      }

      this.props.userHasAuthenticated(true);

    } catch (e) {
      this.setState({ isLoading: false });
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });
    }
  }

  signup = event => {
    this.props.history.push("/signup");
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }
  
  render() {
    return (
      <div className="Login">
        <div className="logo">
          <Image src="./onesource-logo.png"></Image>
        </div>
        <form onSubmit={this.handleSubmit}>
          {this.state.errorRaised &&
              <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                  <h5><b>Oh No! There was a problem...</b></h5>
                  <p>{this.state.error}</p>
              </Alert>
          }
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={this.handleChange}
              type="password"
            />
          </FormGroup>
          <Link to="/login/reset">Forgot password?</Link>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            bsStyle="primary"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in…"
          />
          <div className="hr-sect">don't have a login?</div>
          <LinkContainer to="/signup">
            <LoaderButton
              block
              bsSize="large"
              bsStyle="success"
              text="Signup"
            />
          </LinkContainer>
        </form>
      </div>
    );
  }
}
