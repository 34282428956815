export default {
    s3: {
      REGION: "eu-west-1",
      BUCKET: "onesource-app-uploads"
    },
    cognito: {
      REGION: "eu-west-1",
      USER_POOL_ID: "eu-west-1_L5m7vgMZa",
      APP_CLIENT_ID: "dt24225pvjr5s970cf12amlrs",
      IDENTITY_POOL_ID: "eu-west-1:9c8e84b0-a0df-4b4c-a418-f3f699f11edb"
    },
    apiGatewayInternal: {
      REGION: "eu-west-1",
      URL: "https://v0nwbibcxc.execute-api.eu-west-1.amazonaws.com/prod"
    },
    apiGatewayExternal: {
      REGION: "eu-west-1",
      URL: "https://tdhimtlr32.execute-api.eu-west-1.amazonaws.com/prod"
    },
    apiGatewayAdmin: {
      REGION: "eu-west-1",
      URL: "https://pb7t2kv3ra.execute-api.eu-west-1.amazonaws.com/prod"
    },
    apiGatewayRFC: {
      REGION: "eu-west-1",
      URL: "https://bnfz0kxxe6.execute-api.eu-west-1.amazonaws.com/prod"
    },
    googleAnalytics: {
      TRACKING_ID: "UA-50825763-4"
    }
};


  