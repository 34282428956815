import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import * as tableFunction from  "../../components/TableFunctions"

export default ({
    data,
    onClick
  }) =>
    <BootstrapTable 
        options = { {
            clearSearch: false,
            onRowClick: onClick,
            searchField: tableFunction.createCustomSearchField,
            paginationShowsTotal: tableFunction.renderShowsTotal,
            hideSizePerPage: true,
            sizePerPage: 20,
            sortName: "email",
            sortOrder: "asc"
        } }
        data={ data } 
        pagination
        hover
        clickable
        search>
            <TableHeaderColumn isKey hidden={ true } dataField="username"></TableHeaderColumn>
            <TableHeaderColumn width="200" dataSort={ true } dataField="name">First Name</TableHeaderColumn>
            <TableHeaderColumn width="200" dataSort={ true } dataField="family_name">Surname</TableHeaderColumn>
            <TableHeaderColumn dataSort={ true } dataField="email">Email</TableHeaderColumn>
            <TableHeaderColumn dataSort={ true } width="120" dataField="custom:user_admin">User Admin</TableHeaderColumn>
            <TableHeaderColumn dataSort={ true } width="150" dataField="custom:admin">General Admin</TableHeaderColumn>
            <TableHeaderColumn width="110" dataField="status">Status</TableHeaderColumn>
            <TableHeaderColumn width="100" dataField="enabled">Enabled</TableHeaderColumn>
    </BootstrapTable>;