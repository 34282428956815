import React, { Component } from "react";
import "./Clients.css";
import { Image, Alert, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import InterfaceTable from "./InterfaceTable"
import { API } from "aws-amplify";



export default class Feature extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            allInterfaces: [],
            interfaces: [],
            errorRaised: false,
            error: ""
        }; 
        this.handleDismiss = this.handleDismiss.bind(this);
        this.filterList = this.filterList.bind(this);
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
    }

    filterList(filter) {
        let interfaces = this.state.allInterfaces;
        if (filter !== "all") {
            interfaces = interfaces.filter((inter) => inter.type === filter)
        }
        this.setState({ interfaces : interfaces });
    }

    async componentDidMount() {
        
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const interfaces = await this.interfaces();
            this.setState({ allInterfaces: interfaces });
            this.setState({ interfaces: interfaces });
        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }

        this.setState({ isLoading: false });
    }

    interfaces() {
        return API.get("internal", '/interfaces');
    }

    renderInterfaceList() {
        return ( <InterfaceTable data={this.state.interfaces} includeClient={ true } /> );
    }

    render() {
        return (
        !this.state.isLoading
            ? <div className="Clients">
                <h3>Interfaces
                {this.state.errorRaised &&
                    <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                        <h5><b>Oh No! There was a problem...</b></h5>
                        <p>{this.state.error}</p>
                    </Alert>
                }
                <ButtonToolbar className="adminButtons pullRight">
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("all")} value={1}>All</ToggleButton>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("Core")} value={2}>Core</ToggleButton>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("Email")} value={3}>Email</ToggleButton>
                        <ToggleButton bsStyle="primary" onChange={() => this.filterList("Baris")} value={4}>Baris</ToggleButton>
                        <ToggleButton bsStyle="primary" onChange={() => this.filterList("Datacut")} value={5}>Datacut</ToggleButton>
                    </ToggleButtonGroup>
                </ButtonToolbar>
                </h3>
                
                {this.renderInterfaceList()}
            </div>
            : <div className="loadingContainer">
                <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
            </div>
        );
    }
}
