import React from "react";
import { Route, Switch } from "react-router-dom";
import Analytics from 'react-router-ga';

import config from "./config/appConfig"

//import Home from "./containers/Home";
import NotFound from "./containers/NotFound";

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/user/Login";
import Profile from "./containers/user/Profile";
import Signup from "./containers/user/Signup";
import ResetPassword from "./containers/user/ResetPassword";
import ChangePassword from "./containers/user/ChangePassword";

// Admin Pages
import User from "./containers/admin/Users";

// Client Pages
import Clients from "./containers/client/Clients";
import Client from "./containers/client/Client";
import AdminClient from "./containers/client/AdminClient";
import Feature from "./containers/client/Feature";
import Instance from "./containers/client/Instance";
import Interface from "./containers/client/Interface";

import Monitors from "./containers/monitors/Monitors";
import Reports from "./containers/reports/Reports";


export default ({ childProps }) =>
<Analytics id={config.googleAnalytics.TRACKING_ID}>
  <Switch>
    <AuthenticatedRoute path="/" exact component={Home} props={childProps} />
    <AuthenticatedRoute path="/client" exact component={Clients} props={childProps} />
    <AuthenticatedRoute path="/client/:clientId" exact component={Client} props={childProps} />
    <AuthenticatedRoute path="/adminClient/:clientId" exact component={AdminClient} props={childProps} />
    <AuthenticatedRoute path="/feature" exact component={Feature} props={childProps} />
    <AuthenticatedRoute path="/instance" exact component={Instance} props={childProps} />
    <AuthenticatedRoute path="/interface" exact component={Interface} props={childProps} />
    <AuthenticatedRoute path="/monitors" exact component={Monitors} props={childProps} />
    <AuthenticatedRoute path="/profile" exact component={Profile} props={childProps} />
    <AuthenticatedRoute path="/profile/password" exact component={ChangePassword} props={childProps} />

    <AuthenticatedRoute path="/reports" exact component={Reports} props={childProps} />

    <AuthenticatedRoute path="/users" exact component={User} props={childProps} />

    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps} />

    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>
</Analytics>;

