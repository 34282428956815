import React, { Component } from "react";
import "./Clients.css";
import { Image, Alert } from "react-bootstrap";
import FeatureTable from "./FeatureTable"
import { API } from "aws-amplify";

export default class Feature extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        isLoading: true,
        features: [],
        errorRaised: false,
        error: ""
    }; 
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const features = await this.features();
      this.setState({ features });
    } catch (e) {
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });
    }

    this.setState({ isLoading: false });
  }

  features() {
    return API.get("internal", '/features');
  }

  renderFeaturesList() {
    return ( <FeatureTable data={this.state.features} includeClient={ true } tableRef={node => this.FeatureTable = node} /> );
  }

  render() {
    return (
      !this.state.isLoading
        ? <div className="Clients">
            <h3>Features
              {this.state.errorRaised &&
                  <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                      <h5><b>Oh No! There was a problem...</b></h5>
                      <p>{this.state.error}</p>
                  </Alert>
              }
              <div className="search"></div>
            </h3>
            {this.renderFeaturesList()}
          </div>
        : <div className="loadingContainer">
            <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
          </div>
    );
  }
}
