import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel, ButtonToolbar, Button, Alert } from "react-bootstrap";
import LoaderButton from "../../components/LoaderButton";
import "./Profile.css";

export default class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forename: "",
      surname: "",
      originalForename: "",
      originalSurname: "",
      errorRaised: false,
      error: ""
    };
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    const { attributes = {} } = await Auth.currentUserInfo();      
    this.setState({ forename: attributes['name'] });
    this.setState({ surname: attributes['family_name'] });
    this.setState({ originalForename: attributes['name'] });
    this.setState({ originalSurname: attributes['family_name'] });

  }

  validateForm() {
    return this.state.forename > 0 
    && this.state.surname > 0;
  }

  save = async event => {
    event.preventDefault(); 
    this.setState({ isLoading: true });
    let user = await Auth.currentAuthenticatedUser();
    try {

      await Auth.updateUserAttributes(user, {
        'name': this.state.forename,
        'family_name': this.state.surname
      });
      this.setState({ isLoading: false });
      this.props.history.push("/");

    } catch (e) {

      this.setState({ isLoading: false });
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });

    }
  }

  back = event => {
    this.props.history.push("/");
  }

  handleChange = event => {
    this.setState({
        [event.target.id]: event.target.value 
    });
  }

  render() {
    return (
      <div className="Profile">
        {this.state.errorRaised &&
            <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                <h5><b>Oh No! There was a problem...</b></h5>
                <p>{this.state.error}</p>
            </Alert>
        }
        <FormGroup controlId="forename" bsSize="large">
            <ControlLabel>Forename</ControlLabel>
            <FormControl
              autoFocus
              type="text"
              value={this.state.forename}
              onChange={this.handleChange}
            />
        </FormGroup>
        <FormGroup controlId="surname" bsSize="large">
            <ControlLabel>Surname</ControlLabel>
            <FormControl
              type="text"
              value={this.state.surname}
              onChange={this.handleChange}
            />
        </FormGroup>
        <hr></hr>
        <LinkContainer to="/profile/password">
          <LoaderButton
            block
            bsSize="large"
            bsStyle="success"
            text="Change Password"
          />
        </LinkContainer>
        <hr></hr>
        <ButtonToolbar>
            <Button bsSize="large" onClick={this.back}>Cancel</Button>
            <LoaderButton
              bsSize="large"
              disabled={this.validateForm()}
              onClick={this.save}
              bsStyle="primary"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving…"
            />
        </ButtonToolbar>
        
      </div>
    );
  }
}
