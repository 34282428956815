import React, { Component } from "react";
import "./Clients.css";
import { Image, Button, ButtonToolbar, Alert } from "react-bootstrap";
import ClientTable from "./ClientTable"

import { API } from "aws-amplify";

export default class Clients extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        isLoading: true,
        deleted: true,
        clients: [],
        inactiveClients: [],
        errorRaised: false,
        error: ""
    }; 
    this.showInactive = this.showInactive.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }

  async componentDidMount() {
    
    if (!this.props.isAuthenticated) {
      return;
    }

    this.setState({ deleted: !this.state.deleted });

    try {
      const clients = await this.clients();
      this.setState({ clients: clients.filter(d => d.deleted === true) });
      this.setState({ inactiveClients: clients.filter(d => d.deleted === false) });
    } catch (e) {
      this.setState({ error: e.message });
      this.setState({ errorRaised: true });
    }

    this.setState({ isLoading: false });
  }

  // Allow click through from table to client page with hubspot id used in URL
  handleClientClick = event => {
    this.props.history.push({pathname: "/client/" + event.hubspotId, state: { detail: event, key: 1} });
  }

  addClient = event => {
    this.props.history.push("/adminClient/new");
  }

  showInactive = event =>  {

    event.preventDefault();

    (async () => {
      await this.componentDidMount();
    })();
  }

  clients() {
    return API.get("internal", '/clients');
  }

  renderClientsList() {
    return ( <ClientTable data={ this.state.deleted ? this.state.clients : this.state.inactiveClients } onClick={ this.handleClientClick } /> );
  }

  render() {
    return (
      !this.state.isLoading
        ? <div className="Clients">
            <h3>Clients
            {this.state.errorRaised &&
                <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                    <h5><b>Oh No! There was a problem...</b></h5>
                    <p>{this.state.error}</p>
                </Alert>
            }            
            {this.props.isAdmin
              ? <ButtonToolbar className="adminButtons">
                  {this.state.deleted 
                      ?   <Button bsStyle="success" onClick={this.showInactive}>Show Active Clients</Button>
                      :   <Button bsStyle="danger" onClick={this.showInactive}>Show Inactive Clients</Button>
                  }
                  <Button onClick={this.addClient} bsStyle="primary">Add Client</Button>
                </ButtonToolbar>
              : <div className="search"></div>
            }
            </h3>
           
            {this.renderClientsList()}
          </div>
        : <div className="loadingContainer">
            <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
          </div>
    );
  }
}
