import React, { Component } from "react";
import ChartsEmbedSDK from "@mongodb-js/charts-embed-dom";
import { Image, Alert, Grid, Row, Col, Nav, NavItem } from "react-bootstrap";
import { API } from "aws-amplify";
import "./Reports.css";
import config from "../../config/chartConfig"


async function renderChart(token, chartObj) {
    const sdk = new ChartsEmbedSDK({
      baseUrl: chartObj.BASE_URL, 
      chartId: chartObj.ID,
      height: chartObj.HEIGHT,
      width: chartObj.WIDTH,
      showAttribution: false,
      getUserToken: function () {
        return token;
    }});
    const chart = sdk.createChart({ id: chartObj.ID });
    await chart.render(document.getElementById("report"));
}

export default class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            token: "",
            reportId: "",
            reports: [],
            report: {},
            errorRaised: false,
            error: ""
        }; 
        this.handleDismiss = this.handleDismiss.bind(this);
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
    }

    async componentDidMount() {
        
        if (!this.props.isAuthenticated) {
            return;
        }

        try {

            const token = await this.token();
            this.setState({ token: token });

            this.setState({ reports: config.charts.GENERAL });

            if(Object.keys(this.state.report).length > 0) {
                await renderChart(token, this.state.report)
            }

        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }
        this.setState({ isLoading: false });
    }

    token() {
        return API.get("external", "/mongoChartToken");
    }

    renderReport(report) {
        this.setState({ reportId: report.ID });
        this.setState({ report: report });
        renderChart(this.state.token, report)
    }

    renderReportList(reports) {
        return [{}].concat(reports).map((report, i) =>
            i !== 0 && (
                <Nav bsStyle="pills" stacked activeKey={this.state.reportId} key={report.ID}>
                    <NavItem eventKey={report.ID} onClick={() => this.renderReport(report)} key={report.ID}>
                        {report.NAME}
                    </NavItem>
                </Nav>
            ) 
        );
    }

    render() {
        return (
            !this.state.isLoading
                ?   <div>
                        <h3>Reports
                            {this.state.errorRaised &&
                                <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                                    <h5><b>Oh No! There was a problem...</b></h5>
                                    <p>{this.state.error}</p>
                                </Alert>
                            }
                        </h3>
                        <div className="Reports">
                            <Grid>
                                <Row>
                                    <Col sm={3}>
                                        {this.renderReportList(this.state.reports)}
                                    </Col>
                                    <Col sm={9}>
                                        <div className="Report" id="report"></div>
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    </div>
                :   <div className="loadingContainer">
                        <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                    </div>
        );   
    }
}
