import React, { Component } from "react";
import { Panel, Image, Alert, Grid, Row, Col, Button } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Auth, API } from "aws-amplify";
import Moment from 'moment';
import "./Home.css";

import * as utility from "../components/UtilityFunctions.js";

function monitorColumnFormatter(cell, row) {
  return <div className="monitors"> 
              <h4>{row.name}</h4>
              <p>Status : {utility.site24x7Status(row.status)} | {row.down_reason}</p>
              <p><i>Duration : {row.duration}</i></p>
        </div>;
}

function maintenanceColumnFormatter(cell, row) {
  return <div className="maintenance"> 
              <h4>{row.title}</h4>
              <b>Start : </b>{Moment(row.start_time).format('DD/MM/YYYY HH:mm')}<br></br>
              <b>Finish : </b>{Moment(row.end_time).format('DD/MM/YYYY HH:mm')}<br></br>
              <b>Work being carried out : </b>{utility.getSafe(() => row.body.replace("<br/>", ""), 'Not Specified')}
          </div>;
}

export default class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        isLoading: true,
        site247Loading: true,
        statusHubLoading: true,
        allMonitors: "",
        downMonitors: [],
        problemMonitors: [],
        maintenance: "",
        token: "",
        error: "",
        errorRaised: false
    }; 
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  async componentDidMount() {

    if(!await utility.validSession()) {
      this.props.userHasAuthenticated(false);
      this.props.history.push("/login");
      return;
    }

    try {

        const { attributes = {} } = await Auth.currentUserInfo();
        this.setState({ name: attributes['name'] });
        this.setState({ isLoading: false })

        const currentMonitorStatus = await this.getCurrentMonitorStatus();
        this.setState({ allMonitors: currentMonitorStatus });

        /* FILTER LIST */

        const problemMonitors = currentMonitorStatus.data.monitors.filter(d => d.status === 2);
        const downMonitors = currentMonitorStatus.data.monitors.filter(d => d.status === 0 || d.status === 3);
        
        this.setState({ problemMonitors: problemMonitors });
        this.setState({ downMonitors: downMonitors });

        this.setState({ site247Loading: false })

        const maintenance = await this.getMaintenance();
        this.setState({ maintenance: maintenance });

        this.setState({ statusHubLoading: false })

    } catch (e) {
        this.setState({ error: e.message });
        this.setState({ errorRaised: true });
    }

  }

  async getCurrentMonitorStatus() {
    return API.get("external", '/status');
  }

  getMaintenance() {
    return API.get("external", '/maintenance');
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }

  instances = event => {
    this.props.history.push("/monitors", { monitors: this.state.allMonitors });
  }

  renderDownMonitorsList() {
    return (
      <BootstrapTable 
        bordered={ false }
        data={ this.state.downMonitors } 
        tableHeaderClass={"col-hidden"}
        trClassName={"noTopRowBorder"}
        hover={ false }
        clickable={ false }>
          <TableHeaderColumn isKey name='name' dataFormat={monitorColumnFormatter} dataField='name'></TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderProblemMonitorsList() {
    return (
      <BootstrapTable 
        bordered={ false }
        data={ this.state.problemMonitors } 
        tableHeaderClass={"col-hidden"}
        trClassName={"noTopRowBorder"}
        hover={ false }
        clickable={ false }>
          <TableHeaderColumn tdStyle={ { whiteSpace: 'normal' } } isKey name='name' dataFormat={monitorColumnFormatter} dataField='name'></TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderMaintenanceList() {
    return (
      <BootstrapTable 
        bordered={ false }
        data={ this.state.maintenance } 
        tableHeaderClass={"col-hidden"}
        trClassName={"noTopRowBorder"}
        hover={ false }
        clickable={ false }>
          <TableHeaderColumn tdStyle={ { whiteSpace: 'normal' } } isKey name='title' dataFormat={maintenanceColumnFormatter} dataField='title'></TableHeaderColumn>
      </BootstrapTable>
    );
  }

  render() {
    return (
      <div>
        {this.state.errorRaised &&
          <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
              <h5><b>Oh No! There was a problem...</b></h5>
              <p>{this.state.error}</p>
          </Alert>
        }
        {!this.state.isLoading 
          ? <div className="Home">
              <div className="lander">
               <h4>Welcome {this.state.name}....</h4><br></br>
                <Grid>
                  <Row className="show-grid">
                    <Col sm={8}>
                    <Panel>
                        <Panel.Heading>
                          <Panel.Title componentClass="h4">Site 24x7 Monitors</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                        {!this.state.site247Loading 
                          ? <div>
                              {this.state.downMonitors.length === 0 && this.state.problemMonitors.length === 0 &&
                                  <Alert bsStyle="success">
                                    All monitors appear to be ok
                                  </Alert>
                              }
                              {this.state.downMonitors.length > 0 &&
                                  <Alert bsStyle="danger">
                                    {this.renderDownMonitorsList()}
                                  </Alert>
                              }
                              {this.state.problemMonitors.length > 0 &&
                                  <Alert bsStyle="warning">
                                    {this.renderProblemMonitorsList()}
                                  </Alert>
                              }
                            </div>
                          : <div className="loadingContainer">
                              <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                            </div>
                        }
                        </Panel.Body>
                        <Panel.Footer>
                          <Button onClick={this.instances}>See all monitors</Button>
                        </Panel.Footer>
                      </Panel>
                    </Col>
                    <Col sm={4}>
                      <Panel>
                        <Panel.Heading>
                          <Panel.Title componentClass="h4">Scheduled Maintenance</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                          {!this.state.statusHubLoading 
                            ? <div>
                                {this.state.maintenance.length > 0
                                  ? <Alert bsStyle="info">
                                      {this.renderMaintenanceList()}
                                    </Alert>
                                  : <Alert bsStyle="success">
                                      No Scheduled Maintenance
                                    </Alert>
                                }
                              </div>
                            : <div className="loadingContainer">
                                <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                              </div>
                          }
                        </Panel.Body>
                      </Panel>
                    </Col>
                  </Row>
                </Grid>
              </div>
            </div>
          : <div className="loadingContainer">
              <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
            </div>
        }
      </div>
    )};
}
