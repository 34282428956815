import React, { Component } from "react";
import "./AdminClient.css";
import { Image, FormGroup, ControlLabel, FormControl, Button, Grid, Row, Col, ButtonToolbar, Alert, HelpBlock } from "react-bootstrap";
import { API, Auth } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";
import InstanceTable from "./InstanceTable"

export default class AdminClient extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            new: true,
            id: "",
            clientName: "",
            appName: "",
            hubspotId: "",
            zendeskId: "",
            instances: [],
            deleted: false,
            client: null,
            errorRaised: false,
            error: ""
        }; 
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.deleteInstance = this.deleteInstance.bind(this);
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
    }

    deleteInstance(row) {
        const instance = {
            "context": row.context,
            "appName": row.appName,
            "hubspotId": row.hubspotId,
            "modifiedOn": Date.now(),
            "deleted": true,
            "subdomain": row.subdomain,
            "version": row.version
        };

        API.post("internal", "/instance", {
            body: instance
        })
        .then(result => this.setState({ instances: this.state.instances.filter(m => m.context !== instance.context) }))
        .catch(err => alert(err));     
    }

    deleteButton(cell, row, enumObject, rowIndex) {
        return (
            <Button bsSize="small" bsStyle="danger" onClick={() => this.deleteInstance(row)}>Delete</Button>
        )
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated || !this.props.isAdmin) {
        return;
        }

        try {
            
            if (this.props.location.state === undefined) {
                
            }
            else {

                this.setState({ new: false });
                this.setState({ client: this.props.location.state.detail });

                this.setState({ id: this.props.location.state.detail._id });
                this.setState({ clientName: this.props.location.state.detail.name });
                this.setState({ appName: this.props.location.state.detail.appName });
                this.setState({ hubspotId: this.props.location.state.detail.hubspotId });
                this.setState({ zendeskId: this.props.location.state.detail.zendeskOrganisationId });
                
                this.setState({ instances: this.props.location.instances });

                this.setState({ deleted: this.props.location.state.detail.deleted });
            }
            

        } catch (e) {
            this.setState({ isLoading: false });
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }

        this.setState({ isLoading: false });
    }

    cancel = event => {

        if(this.state.new) {
            this.props.history.push("/client");
        } else {
            this.props.history.push({pathname: "/client/" + this.props.location.state.detail.hubspotId, state: this.props.location.state });
        }

    }

    validateForm() {
        return this.state.clientName.length > 0
        && this.state.hubspotId.length > 0
        && this.state.zendeskId.length > 0;
    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    handleSubmit(event) {

        event.preventDefault();

        this.setState({ isLoading: true });

        // NEED TO TEST HUBSPOT ID
        // NEED TO TEST ZENDESK ID

        const client = {
            "name": this.state.clientName,
            "appName": this.state.appName,
            "hubspotId": this.state.hubspotId,
            "zendeskOrganisationId": this.state.zendeskId,
            "deleted": this.state.deleted
        };

        this.updateClient(client)
            .then(result => this.props.history.push("/client"))
            .catch(err => alert(err));
        
        if (this.state.client.deleted !== this.state.deleted) {
            
            // Update Feature Deleted Status
            this.updateFeatureStatus(this.state.hubspotId, this.state.deleted);
            
        }

        this.setState({ isLoading: false });
    }

    updateClient(client) {
        return API.post("internal", "/client", {
          body: client
        });
    }

    async updateFeatureStatus(hubspotId, deleted) {

        const features = await API.get("internal", '/features/' + hubspotId);
        const { attributes = {} } = await Auth.currentUserInfo();
        const user = attributes['name'] + " " + attributes['family_name'];
        
        features.forEach(function(feature) {

            var updateFeature = {
                    "hubspotId": feature.hubspotId,
                    "context": feature.context,
                    "name": feature.name,
                    "value": feature.value,
                    "note": feature.note,
                    "deleted": deleted,
                    "createdOn": feature.hasOwnProperty('createdBy') ? feature.createdOn : Date.now(),
                    "modifiedOn": Date.now(),
                    "createdBy": feature.hasOwnProperty('createdBy') ? feature.createdBy : "Automated",
                    "modifiedBy": user
            }

            API.post("internal", "/feature", {
                body: updateFeature
            });

        });

    }

    disable = event => {

        // disbale / enable user
        event.preventDefault();
        this.setState({ deleted : !this.state.deleted });
        
    }

    renderInstanceList() {
        return ( <InstanceTable data={this.state.instances} includeClient={ false } deleteButton={ this.deleteButton.bind(this) } /> );
    }

    render() {
        return (
        !this.state.isLoading
            ?   <div className="NewClient">
                    <h3>Add Client</h3>
                        <form onSubmit={this.handleSubmit}>
                        {this.state.errorRaised &&
                            <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                                <h5><b>Oh No! There was a problem...</b></h5>
                                <p>{this.state.error}</p>
                            </Alert>
                        }
                            <Grid>
                                <Row className="show-grid">
                                    <Col sm={5}>
                                        <FormGroup controlId="clientName" bsSize="large">
                                            <ControlLabel>Name</ControlLabel>
                                            <FormControl
                                                autoFocus
                                                type="text"
                                                value={this.state.clientName}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup controlId="appName" bsSize="large">
                                            <ControlLabel>App Name</ControlLabel>
                                            <FormControl
                                                type="text"
                                                value={this.state.appName}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup controlId="hubspotId" bsSize="large">
                                            <ControlLabel>Hubspot Id</ControlLabel>
                                            <FormControl
                                                disabled={!this.state.new}
                                                type="text"
                                                value={this.state.hubspotId}
                                                onChange={this.handleChange}
                                            />
                                            {this.state.new &&
                                                <HelpBlock>This cannot be changed after the client is created so please ensure this is correct</HelpBlock>
                                            }
                                        </FormGroup>
                                        <FormGroup controlId="zendeskId" bsSize="large">
                                            <ControlLabel>Zendesk Organisation Id</ControlLabel>
                                            <FormControl
                                                type="text"
                                                value={this.state.zendeskId}
                                                onChange={this.handleChange}
                                            />
                                        </FormGroup>
                                        <ButtonToolbar>
                                            {!this.state.deleted 
                                                ?   <Button bsSize="small" bsStyle="danger" onClick={this.disable}>Disable</Button>
                                                :   <Button bsSize="small" bsStyle="success" onClick={this.disable}>Enable</Button>
                                            }
                                        </ButtonToolbar>
                                    </Col>
                                    <Col sm={7}>
                                        {this.renderInstanceList()}
                                    </Col>
                                </Row>
                                <Row className="show-grid">
                                    <Col sm={12}>
                                        <ButtonToolbar>
                                            <Button  onClick={this.cancel}>Cancel</Button>
                                            {this.state.new
                                                ?   <LoaderButton
                                                        block
                                                        disabled={!this.validateForm()}
                                                        type="submit"
                                                        bsStyle="primary"
                                                        isLoading={this.state.isLoading}
                                                        text="Create"
                                                        loadingText="Creating Client…"
                                                    />
                                                :   <LoaderButton
                                                        block
                                                        disabled={!this.validateForm()}
                                                        type="submit"
                                                        bsStyle="primary"
                                                        isLoading={this.state.isLoading}
                                                        text="Update"
                                                        loadingText="Updating Client…"
                                                    />
                                            }
                                        </ButtonToolbar>

                                    </Col>
                                </Row>
                            </Grid>
                        </form>
                    </div>
                : <div className="loadingContainer">
                    <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                </div>
            );
    }
}
