import React, { Component } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "./Client.css";

import ClientGeneral from "./ClientGeneral";
import ClientNotes from "./ClientNotes";
import ClientFeatures from "./ClientFeatures";
import ClientStats from "./ClientStats";
import ClientInterfaces from "./ClientInterfaces";

export default class Client extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            key: 1
        }; 
        this.handleSelect = this.handleSelect.bind(this);
    }

    async componentDidMount() {

        if (!this.props.isAuthenticated) {
          return;
        }

        if (this.props.location.state !== undefined) {
            this.setState({ key: this.props.location.state.key });
        }
        else {
            this.props.history.push("/client");
        }
    }

    handleSelect(key) {
        this.setState({ key });
    }


    renderClientGeneral() {
        return ( <ClientGeneral {...this.props} /> );
    }

    renderClientNotes() {
        return ( <ClientNotes {...this.props} /> );
    }

    renderClientFeatures() {
        return ( <ClientFeatures {...this.props} /> );
    }

    renderClientStats() {
        return ( <ClientStats {...this.props} /> );
    }

    renderClientInterfaces() {
        return ( <ClientInterfaces {...this.props} /> );
    }

    render() {

        return (
            <div className="Client">
                {this.props.location.state !== undefined &&
                    <div className="clientName">
                        <h4>{this.props.location.state.detail.name}</h4>
                    </div>
                }
                <Tabs 
                    mountOnEnter
                    unmountOnExit
                    activeKey={this.state.key}
                    onSelect={this.handleSelect}
                    id="client-tabs">

                    <Tab eventKey={1} title="General">
                        {this.renderClientGeneral()}
                    </Tab>
                    <Tab eventKey={2} title="Features">
                        {this.renderClientFeatures()}
                    </Tab>
                    <Tab eventKey={3} title="Notes">
                        {this.renderClientNotes()}
                    </Tab>
                    <Tab eventKey={4} title="Stats">
                        {this.renderClientStats()}
                    </Tab>
                    <Tab eventKey={5} title="Interfaces">
                        {this.renderClientInterfaces()}
                    </Tab>
                </Tabs>

            </div>
        );
    }

}


