import { Storage } from "aws-amplify";

export async function s3Upload(file, hubspotId) {
  const filename = `${hubspotId}-${Date.now()}-${file.name}`;

  const stored = await Storage.put(filename, file, {
    contentType: file.type
  });

  return stored.key;
}

export async function s3Get(file) {
  const url = await Storage.get(file.s3Ref)
  return url;
}