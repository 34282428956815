import React from "react";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import * as tableFunction from  "../../components/TableFunctions"


const options = {
    clearSearch: false,
    searchField: tableFunction.createCustomSearchField,
    paginationShowsTotal: tableFunction.renderShowsTotal,
    hideSizePerPage: true,
    sizePerPage: 20,
    sortName: "context",
    sortOrder: "asc"
};

export default ({
    data,
    includeClient
  }) =>
    <BootstrapTable 
        options = { options }
        data={ data }
        pagination
        search>
            <TableHeaderColumn isKey hidden={ true } dataField="_id"></TableHeaderColumn>
            <TableHeaderColumn dataSort={ true } hidden={ !includeClient } dataField="context">Client</TableHeaderColumn>
            <TableHeaderColumn dataSort={ true } dataField="name">Feature</TableHeaderColumn>
            <TableHeaderColumn dataSort={ true } dataField="value">Value</TableHeaderColumn>
            <TableHeaderColumn columnClassName="multilineColumn" dataField="note">Notes</TableHeaderColumn>
    </BootstrapTable>;