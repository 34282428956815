import React, { Component } from "react";
import { Image, Glyphicon, Button, ButtonToolbar, Modal, FormGroup, FormControl } from "react-bootstrap";

import { Auth, API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";
import NoteTable from "./NoteTable"

import * as utility from "../../components/UtilityFunctions.js";
import * as aws from "../../libs/awsLib.js";

import "./Client.css";

function download(url, filename) {
    fetch(url).then(function(t) {
        return t.blob().then((b)=>{
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}

export default class ClientNotes extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            addingNote: false,
            deleted: true,
            notes: [],
            activeNotes: [],
            note: null,
            modalNoteShow: false,
            modalAddNoteShow: false,
            attachments: [],
            files: [],
            user: "",
            subject: "",
            body: "",
            createdBy: "",
            attachment: "",
            errorRaised: false,
            error: ""
        }; 
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleNoteShow = this.handleNoteShow.bind(this);
        this.handleAddNoteShow = this.handleAddNoteShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.addNote = this.addNote.bind(this);
        this.updateNote = this.updateNote.bind(this);
        this.showInactive = this.showInactive.bind(this);
    }

    validateForm() {
        return this.state.subject.length > 0
        && this.state.body.length > 0;
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
        this.setState({ modalNoteShow: false });
        this.setState({ modalAddNoteShow: false });
    }

    handleNoteShow() {
        this.setState({ modalNoteShow: true });
    }

    handleAddNoteShow() {
        this.setState({ modalAddNoteShow: true });
    }

    handleClose() {
        this.setState({ subject: "" });
        this.setState({ body: "" });
        this.setState({ files: [] });
        this.setState({ modalNoteShow: false });
        this.setState({ modalAddNoteShow: false });
    }

    handleChange = event => {
        this.setState({
          [event.target.id]: event.target.value
        });
    }

    handleFileChange(event) {

        event.stopPropagation();
        event.preventDefault();
        const files =  event.target.files;
        let values = [...this.state.files];
        Array.from(files).forEach(file => values.push(file));
        this.setState({ files: values });

    }

    async updateNote() {

        const update = { 

            "hubspotId": this.state.note.hubspotId,
            "subject": this.state.note.subject,
            "body": this.state.note.body,
            "deleted": !this.state.note.deleted, 
            "modifiedBy": this.state.user,
            "modifiedOn": Date.now(),
            "createdOn": this.state.note.createdOn,
            "createdBy": this.state.note.createdBy,
            "attachments": this.state.note.attachments
        }

        API.post("internal", "/note", {
            body: update
        })
        .then(result => this.refreshList())
        .catch(err => alert(err));     
    
    }

    removeAttachment(event) {

        var array = [...this.state.files]; 
        var index = array.indexOf(event)
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({files: array});
        }
    }

    async downloadAttachment(event) {
        const attachmentURL = await aws.s3Get(event);
        download(attachmentURL, event.name)
    }

    async componentDidMount() {

        this.setState({ deleted: !this.state.deleted });

        try {
            const notes = await this.notes();
            this.setState({ notes });
            const activeNotes = notes.filter(d => d.deleted === false);
            this.setState({ activeNotes });
            const { attributes = {} } = await Auth.currentUserInfo();
            const user = attributes['name'] + " " + attributes['family_name'];
            this.setState({ user: user });
        } catch (e) {
            alert(e);
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }
      
        this.setState({ isLoading: false });

    }

    notes() {
        return API.get("internal", `/notes/${this.props.match.params.clientId}`);
    }

    handleNoteClick = event => {
        this.setState({ attachments: event.attachments});
        this.setState({ note: event });
        this.handleNoteShow();
    }

    handleAddNoteClick = event => {
        this.handleAddNoteShow();
    }


    async addNote() {

        this.setState({ addingNote: true });

        try {

            const hubspotId = this.props.location.state.detail.hubspotId;
            const subject = this.state.subject;
            const body = this.state.body;
            const user = this.state.user;

            const functionWithPromise = item => { 
                return new Promise((resolve) => {
                 resolve(aws.s3Upload(item, this.props.location.state.detail.hubspotId))
            })}
            
            const iterateSequentiallyPromiseArray = async (array, fn) => {
                try {
                  const attachments = [];
                  for (let i = 0; i < array.length; i++) { 
                    const s3response = await fn(array[i]);
                    attachments.push({'name': array[i].name, 's3Ref': s3response})
                  }
                  return attachments; // will be resolved value of promise
                } catch (err) {
                  alert(err);
                }
            };

            const getData = async () => Promise.all(await iterateSequentiallyPromiseArray(this.state.files, functionWithPromise));

            getData().then(function(result) {
 
                const note = {
                        "hubspotId": hubspotId,
                        "subject": subject,
                        "body": body,
                        "deleted": false,
                        "createdOn": Date.now(),
                        "modifiedOn": Date.now(),
                        "createdBy": user,
                        "modifiedBy": user,
                        "attachments": result
                };

                API.post("internal", "/note", {
                    body: note
                    })
                    .then(result => {
                        this.refreshList();
                    })
                    .catch(err => alert(err));
                
            }.bind(this));

        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
            this.setState({ addingNote: false });
        }

        this.setState({ addingNote: false });
 
    }

    async refreshList() {

        this.handleClose();
        const notes = await this.notes();
        this.setState({ notes });
        const activeNotes = notes.filter(d => d.deleted === false);
        this.setState({ activeNotes });
        
    }

    showInactive = event =>  {

        event.preventDefault();
    
        (async () => {
          await this.componentDidMount();
        })();
    }

    handleFileSelect(){
        this.refs.fileUpload.click();
    }

    renderNotesAttachmentsList() {

        return (
            <div>
                <b>Attachments</b>
                {this.state.attachments.map((item, index) => (
                    <div key={index}>
                        <span className="trashLink" onClick={() => { this.downloadAttachment(item)}}>
                            {item.name}
                        </span>
                    </div>
                ))}
            </div>
        );
    }

    renderUploadAttachmentsList() {

        return (
            <div>
                {this.state.files.map(file => (
                    <div key={file.name}>
                        <span>{file.name}</span>
                        <span className="trashLink" onClick={() => { this.removeAttachment(file)}} key={file.name}>
                            &nbsp;&nbsp;
                            <Glyphicon glyph="trash"/>
                        </span>
                    </div>
                ))}
            </div>
        );
    }

    renderNotesList() {
        return ( <NoteTable data={ this.state.deleted ? this.state.notes : this.state.activeNotes } onClick={ this.handleNoteClick } includeClient={ false } /> );
    }

    render() {

        return (
            <div className="Client">
                {!this.state.isLoading
                    ? 
                    <div>
                        <ButtonToolbar className="addButton">
                            {this.state.deleted
                                ?   <Button bsStyle="success" onClick={this.showInactive}>Hide Inactive Notes</Button>
                                :   <Button bsStyle="danger" onClick={this.showInactive}>Show Inactive Notes</Button>
                            }
                            <Button onClick={this.handleAddNoteShow} className="adminButton" bsStyle="primary">Add Note</Button>
                        </ButtonToolbar>
                        {this.renderNotesList()}
                    </div>
                :   <div className="loadingContainer">
                        <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                    </div>
                }
                <Modal bsSize="large" className="noteModal" show={this.state.modalNoteShow} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            {utility.getSafe(() => this.state.note.subject, 'Not Specified')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalBody">
                            {utility.getSafe(() => this.state.note.body, 'Not Specified')}
                        </div>
                        {this.state.attachments.length > 0 &&
                            <div className="modalAttachments">
                                {this.renderNotesAttachmentsList()}
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modalFooter">
                            <span><i>Created By: {utility.getSafe(() => this.state.note.createdBy, 'Not Specified')}</i></span>
                            <span><i> | </i></span>
                            <span><i>Created On: {utility.convertDateTime(utility.getSafe(() => this.state.note.createdOn, 0))}</i></span>
                        </div>
                        {utility.getSafe(() => !this.state.note.deleted, false)
                            ?   <Button bsStyle="danger" onClick={this.updateNote}>Delete</Button>
                            :   <Button bsStyle="success" onClick={this.updateNote}>Reinstate</Button>
                        }
                        <Button onClick={this.handleClose}>Back</Button>
                    </Modal.Footer>
                </Modal>

                <Modal backdrop="static" keyboard={false} bsSize="large" className="noteModal" show={this.state.modalAddNoteShow} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            <FormGroup controlId="subject" bsSize="large" className="modalTitle">
                                <FormControl
                                    autoFocus
                                    type="text"
                                    value={this.state.subject}
                                    onChange={this.handleChange}
                                    placeholder="Subject"
                                />
                            </FormGroup>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="modalBody">
                            <FormGroup controlId="body" bsSize="large" className="modalBodyTextArea">
                                <FormControl
                                    type="text"
                                    componentClass="textarea"
                                    value={this.state.body}
                                    onChange={this.handleChange}
                                    placeholder="Body"
                                />
                            </FormGroup>
                        </div>
                        <div className="modalAttachments">
                            <Button bsStyle="default" onClick={()=>{this.fileUpload.click()}}>Add Attachment
                                <input
                                    ref={(ref) => this.fileUpload = ref}
                                    style={{"display" : "none"}}
                                    type="file" 
                                    multiple   
                                    onChange={this.handleFileChange.bind(this)}
                                    >
                                </input>
                            </Button>
                            {this.state.files.length > 0 &&
                                <div className="modalAttachmentList">
                                    {this.renderUploadAttachmentsList()}
                                </div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        
                        <LoaderButton
                            disabled={!this.validateForm()}
                            onClick={this.addNote}
                            bsStyle="success"
                            isLoading={this.state.addingNote}
                            text="Add Note"
                            loadingText="Adding Note…"
                        />
                        <Button onClick={this.handleClose}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

}


