import React, { Component } from "react";
import { Image, Alert, ButtonToolbar, Button } from "react-bootstrap";
import UserModal from "./UserModal"
import UserTable from "./UserTable"
import { API } from "aws-amplify";

export default class Users extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        isLoading: true,
        modalShow: false,
        user: "",
        users: [],
        allUsers: [],
        activeUsers: true,
        error: "",
        errorRaised: false
    }; 
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  async componentDidMount() {

    if (!this.props.isAuthenticated) {
      return;
    }

    if(!this.props.isSuperAdmin) {
      this.props.history.push("/");
    }


    try {

      const users = await this.users();
      let flatten = [];

      users.Users.forEach(user => {
        
        let flatUser = {
          username: user.Username,
          createdDate: user.UserCreateDate,
          modifiedDate: user.UserLastModifiedDate,
          enabled: user.Enabled,
          status: user.UserStatus
        };

        user.Attributes.forEach(attr => { 
          flatUser[ attr.Name ] = attr.Value;
        });

        flatten.push(flatUser);
      });

      this.setState({ allUsers: flatten });
      this.setState({ users: flatten.filter(m => m.enabled === this.state.activeUsers) });
        
    } catch (e) {
        this.setState({ error: e.message });
        this.setState({ errorRaised: true });
    }

    this.setState({ isLoading: false })

  }

  handleClose() {
    this.setState({ modalShow: false });
    this.setState({ user: "" });
  }

  handleDismiss() {
    this.setState({ errorRaised: false });
  }

  users() {
    return API.get("admin", '/users');
  }

  handleUserClick = event => {
    this.setState({ user: event });
    this.setState({ modalShow: true });
  }

  async enableUser() {
    await API.post("admin", "/user/enable", {
      body: { username: this.state.user.username }
    });
    this.refreshList();
  }

  async disableUser() {
    await API.post("admin", "/user/disable", {
      body: { username: this.state.user.username }
    });
    this.refreshList();
  }

  async adminUser() {

    await API.post("admin", "/user", {
      body: { username: this.state.user.username, admin: this.state.user["custom:admin"] === "0" ? "1" : "0" }
    });
    this.refreshList();
  }

  async showInactive() {
    this.setState({ users: this.state.allUsers.filter(m => m.enabled === !this.state.activeUsers) });
    this.setState({ activeUsers: !this.state.activeUsers });
  }

  async refreshList() {

    this.handleClose();

    (async () => {
      await this.componentDidMount();
    })();
    
  }

  renderUsersList() {
    return ( <UserTable data={this.state.users} onClick={ this.handleUserClick } /> );
  }


  render() {
    return (
      <div>
        <h3>Users
          {this.state.errorRaised &&
            <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                <h5><b>Oh No! There was a problem...</b></h5>
                <p>{this.state.error}</p>
            </Alert>
          }
          <ButtonToolbar className="adminButtons">
          {this.state.activeUsers 
              ?   <Button bsStyle="danger" onClick={() => this.showInactive()}>Show Inactive Users</Button>
              :   <Button bsStyle="success" onClick={() => this.showInactive()}>Show Active Users</Button>
          }
          </ButtonToolbar>
        </h3>
        {!this.state.isLoading 
          ? <div className="Home">
              {this.renderUsersList()}
            </div>
          : <div className="loadingContainer">
              <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
            </div>
        }
        <UserModal
          modalShow={this.state.modalShow}
          user={this.state.user}
          onClose={() => this.handleClose()}
          enableUser={() => this.enableUser()}
          disableUser={() => this.disableUser()}
          adminUser={() => this.adminUser()}
        />
      </div>
    )};
}
