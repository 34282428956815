import React, { Component } from "react";
import "./Clients.css";
import { Image, Alert, ButtonToolbar, ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import { API } from "aws-amplify";
import InstanceTable from "./InstanceTable"

export default class Feature extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
            allInstances: [],
            instances: [],
            errorRaised: false,
            error: ""
        }; 
        this.handleDismiss = this.handleDismiss.bind(this);
        this.filterList = this.filterList.bind(this);
    }

    handleDismiss() {
        this.setState({ errorRaised: false });
    }

    filterList(filter) {
        if(filter === "all") {
            this.setState({ instances: this.state.allInstances });
        } else {
            this.setState({ instances: this.state.allInstances.filter(m => m.subdomain.toLowerCase() === filter) });
        }  
    }

    async componentDidMount() {
        
        if (!this.props.isAuthenticated) {
            return;
        }

        try {
            const instances = await this.instances();
            const dbs = await this.dbs();

            let merged = [];

            for (let i = 0; i < instances.length; i++ ) {
                merged.push({
                    ...instances[i],
                    ...(dbs.find((itmInner) => itmInner.name.toLowerCase() === instances[i].context.toLowerCase()))
                })
            }

            this.setState({ allInstances: merged });
            this.setState({ instances: merged });

        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }

        this.setState({ isLoading: false });
    }

    instances() {
        return API.get("internal", '/instances');
    }

    dbs() {
        return API.get("internal", '/databases');
    }

    renderInstanceList() {
        return ( <InstanceTable data={this.state.instances} includeClient={ true } /> );
    }

    render() {
        return (
        !this.state.isLoading
            ? <div className="Clients">
                <h3>Instances
                {this.state.errorRaised &&
                    <Alert className="alert alert-danger" onDismiss={this.handleDismiss}>
                        <h5><b>Oh No! There was a problem...</b></h5>
                        <p>{this.state.error}</p>
                    </Alert>
                }
                <ButtonToolbar className="adminButtons pullRight">
                    <ToggleButtonGroup type="radio" name="options" defaultValue={1}>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("all")} value={1}>All</ToggleButton>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("app")} value={2}>App</ToggleButton>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("test")} value={3}>Test</ToggleButton>
                        <ToggleButton bsStyle="primary" onClick={() => this.filterList("staging")} value={4}>Staging</ToggleButton>
                    </ToggleButtonGroup>
                </ButtonToolbar>
                </h3>
                
                {this.renderInstanceList()}
            </div>
            : <div className="loadingContainer">
                <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
            </div>
        );
    }
}
