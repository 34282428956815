import React from "react";
import { Grid, Row, Col, Glyphicon } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import * as utility from "../../components/UtilityFunctions.js";
import * as tableFunction from  "../../components/TableFunctions"

function notesColumnFormatter(cell, row) {

    var activeClass = (row.deleted ? "inactive" : "");

    return  <div>
                <Grid className={"note " + activeClass}>
                    <Row>
                        <Col sm={11}>
                            <b>{row.subject}</b>
                            <p>{row.body.substring(0,75)}
                                {row.body.length > 75 &&
                                    <span> ... </span>
                                }
                            </p>
                            <div className="footnote">
                                <span><i>Created By: {row.createdBy}</i></span>
                                <span><i> | </i></span>
                                <span><i>Created On: {utility.convertDateTime(utility.getSafe(() => row.createdOn, 0))}</i></span>
                            </div>
                        </Col>
                        {row.attachments.length > 0 &&
                            <Col sm={1}> 
                                <div className="attachments">
                                    <Glyphicon glyph="paperclip"/>
                                </div>
                            </Col>
                        }
                    </Row>
                </Grid>
            </div>;
}

export default ({
    data,
    onClick,
    includeClient
  }) =>
    <BootstrapTable 
        options = { {
            clearSearch: false,
            onRowClick: onClick,
            searchField: tableFunction.createCustomSearchField,
            paginationShowsTotal: tableFunction.renderShowsTotal,
            hideSizePerPage: true,
            sortName: "createdOn",
            sortOrder: "desc"
        } }
        data={ data } 
        hover 
        clickable
        pagination
        search
        tableHeaderClass={"col-hidden"}>
            <TableHeaderColumn dataField="subject" isKey={true} dataFormat={notesColumnFormatter}></TableHeaderColumn>
            <TableHeaderColumn hidden={ true } dataField="createdOn"></TableHeaderColumn>
    </BootstrapTable>;