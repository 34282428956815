import React, { Component } from "react";
import { Image, Modal, Button } from "react-bootstrap";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import Moment from 'moment';
import MonitorTable from "./MonitorTable"

import "./Monitors.css";

import { API } from "aws-amplify";

export default class Monitors extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            monitors: [],
            monitor: null,
            monitorLoading: false,
            data: "",
            errorRaised: false,
            error: "",
            modalPerformanceShow: false
        }; 
        this.handleDismiss = this.handleDismiss.bind(this);
        this.handlePerformanceShow = this.handlePerformanceShow.bind(this);
        this.handleMonitorClick = this.handleMonitorClick.bind(this);
    }


    async componentDidMount() {

        try {

            const monitors = await this.props.location.state.monitors.data.monitors;
            this.setState({ monitors });     

        } catch (e) {
            this.setState({ error: e.message });
            this.setState({ errorRaised: true });
        }
      
        this.setState({ isLoading: false });

    }

    handleDismiss() {
        this.setState({ errorRaised: false });
        this.setState({ modalPerformanceShow: false });
    }

    handlePerformanceShow() {
        this.setState({ modalPerformanceShow: true });
    }

    async handleMonitorClick(event) {

        this.setState({ monitorLoading: true });
        this.handlePerformanceShow();
        
        const response = await API.get("external", '/report/' + event.monitor_id);
        const data = [];

        let chartData = "";


        if ('CPUUSEDPERCENT' in response) {
            this.setState({ uom: " % " });
            chartData = response.CPUUSEDPERCENT.chart_data;
        }

        if ('RESPONSETIME' in response) {
            this.setState({ uom: " ms " });
            chartData = response.RESPONSETIME.chart_data;
        }


        Object.keys(chartData).forEach(function(key) {
            var obj = {
                name: Moment(chartData[key][0]).format('HH:mm'),
                value: chartData[key][1]
            };
            data.push(obj);
        });

        this.setState({ data : data });
        this.setState({ monitorLoading: false });
    }

    renderMonitorsList() {
        return ( <MonitorTable data={this.state.monitors} onClick={ this.handleMonitorClick } /> );
    }
      
    render() {

        return (
            <div>
                {!this.state.isLoading
                ?   <div>
                        {this.renderMonitorsList()}
                    </div>
                :   <div className="loadingContainer">
                        <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                    </div>
                }
                <Modal keyboard={false} bsSize="large" className="performanceModal" show={this.state.modalPerformanceShow} onHide={this.handleDismiss}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-lg">
                            Today's Performance Statistics ({ this.state.uom })
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {!this.state.monitorLoading
                        ?   <div>
                                <LineChart width={800} height={400} data={this.state.data}>
                                    <Line type="monotone" dataKey="value" stroke="#8884d8" dot={false}/>
                                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                    <XAxis dataKey="name" name="Time"/>
                                    <YAxis type="number" dataKey="value" unit={ this.state.uom }/>
                                    <Tooltip />
                                </LineChart>
                            </div>
                        :   <div className="loadingContainer">
                                <Image alt="loading" src={process.env.PUBLIC_URL + '/loading.gif'} responsive />
                            </div>
                    }    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleDismiss}>Cancel</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            
        );
    }

}

